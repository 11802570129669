<template>
  <div class="supplierWaybillManagePage">
    <!-- 第三步 -->
    <StepThree v-if="currentStep == 3" @stepChange="stepChange"></StepThree>
    <!-- 第四步 -->
    <StepFour v-if="currentStep == 4" @stepChange="stepChange"></StepFour>
  </div>
</template>

<script>
import StepThree from './cmpt/stepThree'
import StepFour from './cmpt/stepFour'

export default {
  data() {
    return {
      currentStep: 3, //当前处于第几步
    }
  },
  methods: {
    //改变步骤
    stepChange(e) {
      this.currentStep = e
    },
  },
  created() {
    this.stepChange(this.$route.query.step)
  },
  components: {
    StepThree,
    StepFour
  },
}
</script>

<style lang="scss" scoped>
.supplierWaybillManagePage {
}
</style>